import React, { ReactEventHandler } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Swal from 'sweetalert2'
import styled from "styled-components"
import Layout from '../components/layout'


import TitleDiv from "../components/elements/TitleDiv"
import CenterDiv from "../components/elements/CenterDiv"

import location from "../assets/contact/location.png"
import mail from "../assets/contact/mail.png"
import phone from "../assets/contact/phone.png"
import whatsapp from "../assets/contact/whatsapp-icon.png"
import addToMailchimp from "gatsby-plugin-mailchimp/src"
import { useState } from 'react';
import emailjs from 'emailjs-com';
import { resetWarningCache } from "prop-types"
import VHCenter from "../components/elements/VHCenter"
import Title from "../components/elements/Title"

const Button = styled.button`
    padding: 0.5% 3% 0.5% 3%;
    background-color: ${(props) => props.color};
    color: white;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

`;

const Div = styled.div`
    width: ${(props) => props.lgScreen};
    margin-bottom: 3%;
    @media (max-width: 768px) {
    width: ${(props) => props.smScreen};
    margin-bottom: 5%;
  }
`;

const StyledInput = styled.input`
    border: 0;
    outline: 0;
    border-bottom: 1px solid grey;
    width: 100%;
`;

const Contact = ({ data }) => {

    const firstTitle = data.TitleDivQuery.edges[0].node.header
    const secondTitle = data.TitleDivQuery.edges[1].node.header
    const thirdTitle = data.TitleDivQuery.edges[2].node.header

    const requestPortfolioText = data.contentBlock.edges[0].node.body.childContentfulRichText.html
    const contactUsText = data.contentBlock.edges[1].node.body.childContentfulRichText.html

    const addressText = data.contentBlock.edges[2].node.body.childContentfulRichText.html
    const addressIcon = data.contentBlock.edges[2].node.image.fluid.src
    const addressIconWidth = data.contentBlock.edges[2].node.width
    const addressIconHeight = data.contentBlock.edges[2].node.height

    const emailText = data.contentBlock.edges[3].node.body.childContentfulRichText.html
    const phoneText = data.contentBlock.edges[4].node.body.childContentfulRichText.html
    const whatsappText = data.contentBlock.edges[5].node.body.childContentfulRichText.html


    let [email, setEmail] = useState('');
    let [name, setName] = useState('');
    let [message, setMessage] = useState('');
    let [subject, setSubject] = useState('');
    let [name2, setName2] = useState('');
    let [email2, setEmail2] = useState('');



    const handleEmailChange = event => {
        setEmail(event.currentTarget.value);
    };
    const handleEmail2Change = event => {
        setEmail2(event.currentTarget.value);
    };
    const handleName2Change = event => {
        setName2(event.currentTarget.value);
    };

    const handleNameChange = event => {
        setName(event.currentTarget.value);
    };
    const handleSubjectChange = event => {
        setSubject(event.currentTarget.value);
    };

    const handleMessageChange = event => {
        setMessage(event.currentTarget.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const templateId = 'contact_us';
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');

        sendFeedback(templateId, { message_html: message, from_name: name, reply_to: email, subject: subject });
    }
    const requestForPortfolio = e => {
        e.preventDefault();
        const templateId = 'corporate_portfolio';
        setName2('');
        setEmail2('');

        sendFeedback(templateId, { from_name: name2, reply_to: email2 });
    }


    function sendFeedback(templateId, variables) {
        emailjs.send(
            'gmail', templateId,
            variables, 'user_aVNdeoj10gPzyYDyQwoPV'
        ).then(res => {
            console.log('Email successfully sent!');
            Swal.fire(
                'Form submitted!',
                'We will get back to you soon',
                'success'
            )
        })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => {
                console.error('Oh well, you failed. Here some thoughts on the error that occurred:', err);
                Swal.fire(
                    'Opss...',
                    'Looks like you missed some thing, try submitting another form',
                    'error'
                )
            })
    }

    return (
        <Layout>
            <SEO title="Contact" />
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7829954267927!2d103.83545631482464!3d1.3052953620804046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19939f6a63ef%3A0xf61f718aa56edee2!2s42%20Cairnhill%20Rd%2C%20Singapore%20229661!5e0!3m2!1sen!2sus!4v1574430313103!5m2!1sen!2sus" className="w-100" height="450" frameborder="0" style={{ border: "0" }} allowfullscreen=""></iframe>
            </div>
            <TitleDiv color="#feb600" style={{ marginTop: "-1%", paddingTop: "0.5%" }}>
                <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
            </TitleDiv>
            <div className="container avenir-heavy font-size" style={{ color: "#626363" }}>
                <table>
                    <tr>
                        <td >
                            <p><img className="mx-2 mb-0" width="18px" height="27px" src={location} /></p>
                        </td>
                        <td className="" >
                            <span dangerouslySetInnerHTML={{ __html: addressText }}></span>

                        </td>
                    </tr>
                    <br />
                    <tr>
                        <td>
                            <p> <img className="m-2" width="19px" height="14px" src={mail} /></p>
                        </td>
                        <td>
                            <span dangerouslySetInnerHTML={{ __html: emailText }}></span>
                        </td>
                    </tr>
                    <br />
                    <tr>
                        <td>
                            <p><img className="m-2" width="19px" height="19px" src={phone} /></p>
                        </td>
                        <td>
                            <span dangerouslySetInnerHTML={{ __html: phoneText }}></span>
                        </td>
                    </tr>
                    <br />
                    <tr>
                        <td>
                            <p><img className="m-2" width="20px" height="20px" src={whatsapp} /></p>
                        </td>
                        <td>
                            <span dangerouslySetInnerHTML={{ __html: whatsappText }}></span>
                        </td>
                    </tr>
                </table>
            </div>
            <TitleDiv color="#85b8cd">
                <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter></TitleDiv>
            <div className="container font-size avenir-heavy" style={{ color: "#626363" }}>
                <div dangerouslySetInnerHTML={{ __html: requestPortfolioText }}></div>
                <form onSubmit={requestForPortfolio}>
                    <Div smScreen="100%" lgScreen="40%" class="form-group" >
                        <label for="name">Your Name (required)</label>
                        {/* <input onChange={handleName2Change} style={{ borderBottom: "1px solid #626363" }} type="text" value={name} class="form-control border-top-0 border-left-0 border-right-0" id="name" required /> */}
                        <p><StyledInput type="text" value={name2} name="name2" required onChange={handleName2Change} /></p>
                    </Div>
                    <Div smScreen="100%" lgScreen="40%" class="form-group">
                        <label for="email">Your Email (required)</label>
                        {/* <p><StyledInput onChange={handleEmailChange} type="email" value={email} class="form-control border-top-0 border-left-0 border-right-0" id="email" required /></p></StyledInput> */}
                        <p><StyledInput type="email" value={email2} name="email2" required onChange={handleEmail2Change} /></p>
                    </Div>
                    <button style={{ backgroundColor: "#85b8cd", paddingRight: "3%", paddingLeft: "3%", height: "37px", }} type="submit" class="avenir-black mybtn">Send</button>

                </form>
            </div>
            <TitleDiv color="#ed6c2b">
                <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: thirdTitle }}></div></Title></div></VHCenter>
            </TitleDiv>
            <div className="container avenir-heavy font-size" style={{ color: "#626363", marginLeft: "auto", marginBottom: "5%" }}>
                <div dangerouslySetInnerHTML={{ __html: contactUsText }}></div>
                <form onSubmit={handleSubmit}>
                    <Div smScreen="100%" lgScreen="40%" class="form-group">
                        <label for="name">Your Name (required)</label>
                        <p><StyledInput type="text" value={name} name="name" required onChange={handleNameChange} /></p>
                        {/* <input style={{ borderBottom: "1px solid #626363" }} type="text" value={name} class="form-control border-top-0 border-left-0 border-right-0" id="name" required onChange={handleNameChange} /> */}
                    </Div>
                    <Div smScreen="100%" lgScreen="40%" class="form-group">
                        <label for="email">Your Email (required)</label>
                        <p><StyledInput type="email" value={email} name="email" required onChange={handleEmailChange} /></p>
                        {/* <input style={{ borderBottom: "1px solid #626363" }} type="email" value={email} class="form-control border-top-0 border-left-0 border-right-0" id="email" required onChange={handleEmailChange} /> */}
                    </Div>
                    <Div smScreen="100%" lgScreen="40%" class="form-group">
                        <label for="subject">Subject</label>
                        <p><StyledInput type="text" value={subject} name="email" required onChange={handleSubjectChange} /></p>
                        {/* <input style={{ borderBottom: "1px solid #626363" }} type="text" value={subject} class="form-control border-top-0 border-left-0 border-right-0" id="subject" onChange={handleSubjectChange} /> */}
                    </Div>
                    <Div smScreen="100%" lgScreen="40%" class="form-group">
                        <label for="comment">Your Message</label>
                        <textarea style={{ border: "1px solid #626363" }} value={message} class="form-control" rows="5" id="comment" onChange={handleMessageChange} />
                    </Div>
                    <button style={{ backgroundColor: "#ed6c2b", paddingRight: "3%", paddingLeft: "3%", height: "37px" }} type="submit" class="avenir-black mybtn">Send</button>
                </form>
            </div>
        </Layout >
    )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    contentBlock: allContentfulBlocks(
        filter: { path: { eq: "/contact" } }
        sort: { fields: orderId }
      ) {
        edges {
          node {
            width
            height
            body {
              childContentfulRichText {
                html
              }
            }
            image{
                fluid{
                    src
                }
            }
          }
        }
      }
    
    TitleDivQuery: allContentfulTitleDiv(filter: { path: { eq: "/contact" }}, sort: { fields: orderId })
    {
      edges {
        node {
          orderId
          header 
        }
      }
    }
    
  }
`
